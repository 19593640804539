import { Component, Input, OnInit } from '@angular/core';
import { ProfilesGroupedByVulnerabilityLevelDto } from '../../../../../services/api/models';

@Component({
  selector: 'ngx-accumulated-bars-graph',
  templateUrl: './accumulated-bars-graph.component.html',
  styleUrls: ['./accumulated-bars-graph.component.scss']
})
export class AccumulatedBarsGraphComponent implements OnInit{
  @Input() colorScheme;
  @Input() graphData: Array<ProfilesGroupedByVulnerabilityLevelDto>;

  values = [];
  multi: any[];
  view: any[] = [300, 158];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Country';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = 'Population';
  animations: boolean = true;
  constructor() {
  }

  ngOnInit(): void {
    console.log('barras', this.graphData)
    if (this.graphData) {
      this.formatValues()
    }
  }

  formatValues() {
    this.graphData.map(itm => {
      this.values.push({
        "name": itm.label,
        "series": [
          {
            "name": "Vulnerabilidad baja",
            "value": itm.lowVulnerabilitytotalProfileCount
          },
          {
            "name": "Vulnerabilidad media",
            "value": itm.mediumVulnerabilitytotalProfileCount
          },
          {
            "name": "Vulnerabilidad alta",
            "value": itm.highVulnerabilitytotalProfileCount
          },
        ]
      })
    })
  }
  onSelect(event) {
    console.log(event);
  }

  formatInteger(value: any): string {
    return Math.round(value).toString(); // Convertimos el valor a entero
  }
}
