import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-dashboard-area-chart',
  templateUrl: './dashboard-area-chart.component.html',
  styleUrls: ['./dashboard-area-chart.component.scss']
})
export class DashboardAreaChartComponent implements OnInit {
  @Input() colorScheme;

  view: any[] = [300, 158];

  values = [
    {
      "name": "Vulnerabilidad",
      "series": [
        {
          "name": "Sem. 1",
          "value": 40
        },
        {
          "name": "Sem. 2",
          "value": 70
        },
        {
          "name": "Sem. 3",
          "value": 36
        },
        {
          "name": "Sem. 4",
          "value": 86
        },{
          "name": "Sem. 5",
          "value": 25
        }

      ]
    },
  ];

  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Population';
  timeline: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  onSelect(event) {
    console.log(event);
  }
}
