import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { ProfilesGroupedByVulnerabilityLevelDto } from '../../../../../services/api/models';

@Component({
  selector: 'ngx-dashboard-pie-graph',
  templateUrl: './dashboard-pie-graph.component.html',
  styleUrls: ['./dashboard-pie-graph.component.scss']
})
export class DashboardPieGraphComponent implements OnInit {  
  @Input() colorScheme;
  @Input() graphData: ProfilesGroupedByVulnerabilityLevelDto;

  results = [
    {
      "name": "Vulnerabilidad Alta",
      "value": 17 ,
      "extra": {
        "code": "de"
      }
    },
    {
      "name": "Vulnerabilidad Media",
      "value": 22 ,
      "extra": {
        "code": "us"
      }
    },
    {
      "name": "Vulnerabilidad Baja",
      "value": 20,
      "extra": {
        "code": "fr"
      }
    },
  ]
  constructor() { 
  
  }
  
  ngOnInit() {
    console.log('data', this.graphData)
    this.results[0].value = this.graphData.highVulnerabilitytotalProfileCount || 0;
    this.results[1].value = this.graphData.mediumVulnerabilitytotalProfileCount || 0;
    this.results[2].value = this.graphData.lowVulnerabilitytotalProfileCount || 0;
    console.log('results', this.results)
  }

  ngOnDestroy(): void {
  }
}
