import { ChangeDetectorRef, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PaginationService {
  page = {
    totalElements: 100,
    pageNumber: 0,
    size: 10
  };

  constructor() {}

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset ?? pageInfo;
  }

  /**
   *
   * @returns if the following action must be performed or not
   */
  prevPage(): boolean {
    if (this.page.pageNumber > 0) {
      this.page.pageNumber--;
      return true;
    }
    return false;
  }

  /**
   *
   * @returns if the following action must be performed or not
   */
  nextPage(): boolean {
    const totalPages = Math.ceil(this.page.totalElements / this.page.size);
    if (this.page.pageNumber < totalPages - 1) {
      this.page.pageNumber++;
      return true;
    }
    return false;
  }

  getPages() {
    return Math.ceil(this.page.totalElements / this.page.size);
  }

  getPageNumbers(): Array<number|string> {
    const totalPages = this.getPages();
    const currentPage = this.page.pageNumber + 1;
    let pages: Array<number|string> = [];

    if (totalPages <= 3) {
      // Si hay 3 o menos páginas, muestra todas
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Más de tres páginas requiere lógica para puntos suspensivos
      if (currentPage <= 2) {
        // Primeros casos: siempre se muestra "1 2 3 ..."
        pages = [1, 2, 3];
        if (totalPages > 3) pages.push('...');
      } else if (currentPage >= totalPages - 1) {
        // Últimos casos: Mostrar últimos tres números y evitar puntos suspensivos al final
        pages = ['...', totalPages - 2, totalPages - 1, totalPages];
      } else {
        // Caso general para páginas intermedias
        pages = ['...', currentPage - 1, currentPage, currentPage + 1];
        if (currentPage + 1 < totalPages) pages.push('...'); // Asegurarse de que siempre que no estemos en las últimas dos páginas, aparezca '...'
      }
    }
    return pages;
}


// TICSMART CORPORATIVE PAGINATION

getPagesV2(pagination) {
  return Array.from({ length: pagination.lastPage }, (_, i) => i + 1);
}

updatePageV2(value, pagination) {
  if (value > 0 && value <= pagination.lastPage) {
    pagination.actualPage = value;
  }
  return pagination;
}

updateRowsV2(pagination) {
  pagination.actualPage = 1;
  return pagination;
}

}
