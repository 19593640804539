import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { CoreProvider } from "../../../services/services/core.provider";
import { ComponentsService } from "../../../services/services/components.service";
import { AdminDashboardDto, DashboardDto, ProfileModelDto } from "../../../services/api/models";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { SidebarService } from "../../../services/services/sidebar.service";

@Component({
  selector: "ngx-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild("modalConfirm") modalConfirm: TemplateRef<any>;
  @ViewChild("eggContainer") eggContainer: ElementRef;
  @ViewChild("eggImage") eggImage: ElementRef;
  private sidebarSub: Subscription;

  colorScheme = {
    domain: ['#B8A6FF', '#9AC7FF', '#F9CCFE', '#F3E2FA','#D0F0E3','#B8A6FF','#B8A6FF'],
  };
  adminGraphicsLoaded: boolean = true;

  workspaceAverageVulnerability: number;
  personalVulnerability: number;
  // Dato recibido del servidor
  dashboard: DashboardDto;

  adminDataGraphs: AdminDashboardDto;
  
  dashboardSubscription: Subscription;
  userDataSubscription: Subscription;
  userData: ProfileModelDto = null;

  showPersonalText: boolean = false;
  showGlobalText: boolean = false;

  animationLoaded = false;
  playingAnimation = false;

  frameIndexRing: number = 0;
  frameIndexEgg: number = 0;

  animationIntervalRing: any;
  animationIntervalEgg: any;

  ringImageSrc: string = `assets/imgs/dashboard/rings/golden/golden-ring-0.png`;
  eggImageSrc: string = `assets/imgs/dashboard/eggs/purple/purple-egg-0.png`;
  ringImageColor: string = 'purple';
  eggImageColor: string = 'purple';

  imgsMapped = {
    high: '90',
    midhigh: '70',
    midlow: '40',
    low: '10',
  }

  isSidebarExpanded: boolean;
  constructor(
    public readonly core: CoreProvider,
    public readonly componentsServices: ComponentsService,
    public readonly actRouter: ActivatedRoute,
    public readonly router: Router,
    private readonly sidebarService: SidebarService
  ) {}

  async ngOnInit(): Promise<void> {
    this.sidebarService.toggleSidebarVisibility(true, false);
    this.userDataSubscription = this.core.getUserData().subscribe({
      next: (data) => {
        this.userData = data;
        this.checkGraphicsColors();
      },
      error: (error: any) => {
        this.componentsServices.showToast('danger', 'Se ha producido un error al obtener los datos de tu usuario', 'Error!');
      }});

    this.actRouter.queryParams.subscribe(async (params) => {
      if (params["reload"]) {
        this.router.navigateByUrl(`/account/dashboard`);
        await this.refresh(true);
      }
    });

    this.sidebarSub = this.sidebarService.sidebarVisible$.subscribe((isVisible: boolean) => {
      this.isSidebarExpanded = isVisible;
    });
    await this.refresh();
  }

  ngOnDestroy(): void {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
    if (this.dashboardSubscription) {
      this.dashboardSubscription.unsubscribe();
    }
    if (this.sidebarSub) {
      this.sidebarSub.unsubscribe();
    }
  }

  showConfirmWorkspaceModal() {
    this.componentsServices.openModal(this.modalConfirm, null, false);
  }

  async refresh(reload?) {
    if (reload) {
      await this.core.fetchDashboardData();
    }

    if (this.core.isUserAdmin()) {
      console.log('es user en dashboard, ')
      this.dashboardSubscription = this.core.getDashboardData().subscribe({
        next: (data: AdminDashboardDto) => {
          this.adminDataGraphs = data;
          console.log('gráficos admin', this.adminDataGraphs)
        },
        error: (error: any) => {
          this.componentsServices.showToast('danger', 'Se ha producido un error al obtener los datos de tu empresa', 'Error!');

        }
      });
    } else {
      this.dashboardSubscription = this.core.getDashboardData().subscribe({
        next: (data: DashboardDto) => {
          this.dashboard = data;
          this.personalVulnerability = this.calcAverageVulnerability('generalVulnerabilityPrediction');
          this.workspaceAverageVulnerability =this.calcAverageVulnerability('averageWorkspaceVulnerabilityPrediction');
          this.checkGraphicsColors();

          if (reload) {
            this.animationLoaded = false;
            this.playingAnimation = false;
            // this.animateDashboard();
          }
          
        },
        error: (error: any) => {
          this.componentsServices.showToast('danger', 'Se ha producido un error al obtener los datos de tu dashboard', 'Error!');
        }
      });
    }
  }

  approximatePercent(percent) {
    const steps = [20, 40, 60, 80, 100];
    return steps.reduce((prev, curr) =>
      Math.abs(curr - percent) < Math.abs(prev - percent) ? curr : prev
    );
  }

  closeModalConfirm(ref) {
    ref.close();
  }


  getGeneralVulnerabilityEgg(vulnerability: string) {
    let vulnerabilityNumber: number = parseFloat(vulnerability);
  
    if (!isNaN(vulnerabilityNumber)) {
      // Obtenemos el múltiplo inferior de 10
      const lowerMultiple = Math.floor(vulnerabilityNumber / 10) * 10;
  
      // Calculamos la diferencia con el múltiplo inferior
      const difference = vulnerabilityNumber - lowerMultiple;
  
      // Si la diferencia es 5 o mayor, redondeamos al siguiente múltiplo de 10
      // Si es menor a 5, redondeamos al múltiplo inferior
      return difference >= 5 ? lowerMultiple + 10 : lowerMultiple;
    } else {
      return 0;
    }
  }

  getVulnerability(vulnerability) {
    let vulnerabilityNumber: number = parseFloat(vulnerability);
    if (!isNaN(vulnerabilityNumber)) {
      return Math.round(vulnerabilityNumber * 100) / 100;
    } else {
      return 0
    }
  }

  calcAverageVulnerability(prop: 'generalVulnerabilityPrediction' | 'averageWorkspaceVulnerabilityPrediction') {
    // Declarar una variable para los valores válidos
    let validValues: number[] = [];
      
      // Trata 'dashboard' como un solo objeto
      const singleValue = parseFloat(
        (this.dashboard as DashboardDto)[prop] || "NaN"
      );
      // Agregar el valor a validValues solo si no es NaN
      if (!isNaN(singleValue)) {
        validValues.push(singleValue);
      }
    
  
    // Si no hay valores válidos, devolver 0
    if (validValues.length === 0) {
      return 0;
    }
  
    // Calcular la media
    const sum = validValues.reduce((acc, curr) => acc + curr, 0);
    const average = sum / validValues.length;
  
    return Math.round(average * 100) / 100;
  }
  

  
  startRingAnimation() {
    this.animationIntervalRing = setInterval(() => {
      this.updateRingImage();
      this.frameIndexRing += 5;
      let diference = Math.abs(this.workspaceAverageVulnerability - this.personalVulnerability);
      if (this.frameIndexRing >= diference) {
        clearInterval(this.animationIntervalRing); // Detener la animación de los anillos
      }
    }, 100);
  }
  
  startEggAnimation() {
    this.animationIntervalEgg = setInterval(() => {
      this.updateEggImage();
      this.frameIndexEgg += 5;
  
      if (this.frameIndexEgg >= this.personalVulnerability) {
        clearInterval(this.animationIntervalEgg); // Detener la animación de los huevos
        this.playingAnimation = false; // Marca que la animación ha terminado
      }
    }, 200);
  }
  
  updateRingImage() {
    this.ringImageSrc = `assets/imgs/dashboard/rings/${this.ringImageColor}/${this.ringImageColor}-ring-${this.frameIndexRing}.png`;
  }
  
  updateEggImage() {
    this.eggImageSrc = `assets/imgs/dashboard/eggs/${this.eggImageColor}/${this.eggImageColor}-egg-${this.frameIndexEgg}.png`;
  }

  getPersonalVariation() {
    if (this.dashboard) {
      let personalCurrent = parseFloat((this.dashboard as DashboardDto).generalVulnerabilityPrediction);
      let personalPast = parseFloat((this.dashboard as DashboardDto).previousGeneralVulnerabilityPrediction);
      
      if (isNaN(personalCurrent) || isNaN(personalPast)) {
        return 'Todavía no tengo datos de tu <strong>vulnerabilidad</strong>.';     
      }
  
      if (personalPast === 0) {
        // Manejo especial para el caso en que personalPast es 0
          return 'Tu <strong>vulnerabilidad</strong> actual es de un <strong>' + Math.abs(personalCurrent).toFixed(0) + '%.</strong>';
      }
  
      let difference = personalCurrent - personalPast;
      let absolutePercentageChange = Math.abs(difference).toFixed(0);
  
      if (personalCurrent > personalPast) {
        return `Ha <strong>aumentado</strong> en un <strong>${absolutePercentageChange}%</strong> tu riesgo`;
      } else if (personalCurrent < personalPast) {
        return `Ha <strong>disminuido</strong> en un <strong>${absolutePercentageChange}%</strong> tu riesgo`;
      } else {
        return 'Tu riesgo se ha mantenido igual';
      }
    }
  }
  getGlobalVariation() {
    if (this.dashboard) {
      let personalCurrent = parseFloat((this.dashboard as DashboardDto).generalVulnerabilityPrediction);
      let globalVulnerability = parseFloat((this.dashboard as DashboardDto).averageWorkspaceVulnerabilityPrediction);
  
      if (globalVulnerability === 0 || isNaN(globalVulnerability)) {
          return 'No hay datos previos de tus compañeros para compararte.';
      } else {
        if (isNaN(personalCurrent)) {
            return 'Como no tengo datos de tu <strong>vulnerabilidad</strong>, te muestro la <strong>media</strong> de tus compañeros';  
        }
      }

  
  
      let difference = personalCurrent - globalVulnerability;
      let absolutePercentageChange = Math.abs(difference).toFixed(0);
  
      if (personalCurrent > globalVulnerability) {    
        return `Estás un <strong>${absolutePercentageChange}% más vulnerable</strong> que el resto de tu organización`;
      } else if (personalCurrent < globalVulnerability) {
        return `Estás un <strong>${absolutePercentageChange}% menos vulnerable</strong> que el resto de tu organización`;
      } else {
        return 'Tu <strong>riesgo</strong> es igual al resto de tu organización';
      }
    }
  }

  tooggleUserInfo() {
    this.showPersonalText = !this.showPersonalText;
    this.playingAnimation = true; 
    this.startEggAnimation();
  }

  toogleRingInfo() {
    this.showGlobalText = !this.showGlobalText;
    this.playingAnimation = true; 
    this.startRingAnimation();
  }

  checkGraphicsColors() {
    if ( this.dashboard ) {
      // Rings Color 
      if ((this.dashboard as DashboardDto).averageWorkspaceVulnerabilityPrediction > (this.dashboard as DashboardDto).generalVulnerabilityPrediction) {
        this.ringImageColor = 'golden';
      }else {
        this.ringImageColor = 'purple';
      };

      // Eggs Color 
      if ((this.dashboard as DashboardDto).generalVulnerabilityPrediction < (this.dashboard as DashboardDto).previousGeneralVulnerabilityPrediction) {
        this.eggImageColor = 'golden';
      }else {
        this.eggImageColor = 'purple';
      };
    }
  }
}
