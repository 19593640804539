<ng-container *ngIf="adminDataGraphs && core.isUserAdmin(); else isUserView">
  <section class="d-flex mb-4 header-section flex-column">
    <div class="w-100">
      {{'¿Cual es la vulnerabilidad de tu empresa?'}}
    </div>
    <h1 class="m-0">{{'¡HOY!'}}</h1>
  </section>
  <section class="d-flex flex-column flex-lg-row">
    <div class="d-flex flex-column justify-content-around w-25">
      <nb-card class="">
        <span>{{'Departamento'}}</span>

        <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [graphData]="adminDataGraphs.profilesGroupedByDepartmentAndVulnerabilityLevel"></ngx-accumulated-bars-graph>
      </nb-card>
      <nb-card>
        <span>{{'Gráfico 2'}}</span>
        <ngx-dashboard-area-chart [colorScheme]="colorScheme"></ngx-dashboard-area-chart>
      </nb-card>
      <nb-card>
        <span>{{'Cargo'}}</span>
        <ngx-accumulated-bars-graph [colorScheme]="colorScheme" [graphData]="adminDataGraphs.profilesGroupedByPositionCompanyAndVulnerabilityLevel"></ngx-accumulated-bars-graph>
      </nb-card>
  
    </div>
    <div id="central-pie-container" #eggContainer>
      <ngx-dashboard-pie-graph [colorScheme]="colorScheme" [graphData]="adminDataGraphs.profilesGroupedByVulnerabilityLevel"></ngx-dashboard-pie-graph>
    </div>
    <div class="d-flex flex-column justify-content-around w-25">
      <nb-card>
        <span>{{'Gráfico 4'}}</span>
        <ngx-dashboard-area-chart [colorScheme]="colorScheme"></ngx-dashboard-area-chart>
      </nb-card>
      <nb-card>
        <span>{{'Gráfico 5'}}</span>
        <ngx-accumulated-bars-graph [colorScheme]="colorScheme"></ngx-accumulated-bars-graph>
      </nb-card>
      <nb-card>
        <span>{{'Gráfico 6'}}</span>
        <ngx-dashboard-pie-graph [colorScheme]="colorScheme" [graphData]="adminDataGraphs.profilesGroupedByVulnerabilityLevel"></ngx-dashboard-pie-graph>
      </nb-card>
    </div>
  </section>
</ng-container>

<ng-template #isUserView>
  <ng-container *ngIf="dashboard && !core.isUserAdmin();">
    <div class="d-flex justify-content-between align-items-center p-4 h-100">
      <div id="dashboard-container" #eggContainer>
        <ng-template #personalInfo>
          <div class="personal-info-card margin-four">
            <span [innerHTML]="getPersonalVariation()">
            </span>
          </div>
        </ng-template>
        <div class="father-container position-relative h-100 gap d-flex w-100 justify-content-around">
          <div class="d-flex container-first flex-column ">
            <div *ngIf="!showPersonalText; else personalInfo" class="personal-info-card">
              <h3>{{ 'Este es tu nivel individual'}}</h3>
              <br>
              <span class="d-flex pb-3">
                {{ 'Se basa en tus rasgos de personalidad, los contenidos y las simulaciones que has completado.'}}
              </span>
              <span class="d-flex pb-3">{{'Recuerda: A mayor nivel, más vulnerabilidad. Un nivel bajo significa que estás menos vulnerable a caer en un ataque. '}}</span>
              <div>
                <button (click)="tooggleUserInfo()" nbButton hero [status]="'info'" class="button-form"
                  [shape]="'round'">{{ 'Muéstramelo'}}
                  <img src="assets/imgs/chevron-right.svg">
                </button>
              </div>
            </div>
            <div *ngIf="userData"
              class=" header-text flex-grow-1 text-center text-md-center text-lg-left d-flex flex-column justify-content-center"
              [ngClass]="showPersonalText ? 'align-self-center flex-grow-1' : ''">
              <h2 class="title-user">{{ 'Hola, ' + userData?.name + ','}}</h2><br>
              <div>
                {{ 'Estos son tus niveles de vulnerabilidad, internos y externos.'}}
              </div>
            </div>
          </div>


          <div *ngIf="eggImageSrc && ringImageSrc"
            class="d-flex image-container position-relative justify-content-center justify-content-md-center justify-content-lg-center justify-content-xl-center h-100 align-items-center">
            <img class="d-flex position-absolute" id="dashboard-image" #eggImage [src]="ringImageSrc" alt="Dashboard">
            <img class="d-flex" id="dashboard-image" #eggImage [src]="eggImageSrc" alt="Vulnerability level">
            <div id="dashboard-image" class="position-absolute">
              <svg height="300" width="500" class="svg-top" [ngClass]="!isSidebarExpanded ? 'short-top' : ''">
                <line x1="200" y1="150" [attr.x2]="'100'" [attr.y2]="'50'" style="stroke:black;stroke-width:1.5" />
                <line x1="200" y1="150" [attr.x2]="'400'" [attr.y2]="'100'" style="stroke:black;stroke-width:1.5" />
              </svg>

              <svg height="300" width="500" class="svg-bottom" [ngClass]="!isSidebarExpanded ? 'short-bottom' : ''">
                <line x1="200" y1="150" [attr.x2]="'120'" [attr.y2]="'80'" style="stroke:black;stroke-width:1.5" />
                <line x1="200" y1="150" [attr.x2]="'350'" [attr.y2]="'135'" style="stroke:black;stroke-width:1.5" />
              </svg>
            </div>
          </div>


          <div
            class="d-flex gap flex-column global-info-card global-info-card-container align-items-center justify-content-end">
            <div *ngIf="!showPersonalText; else personalInfoMobile" class="personal-info-card-mobile">
              <h3>{{ 'Tu nivel individual'}}</h3>
              <span class="d-flex pb-3">
                {{ 'El porcentaje interior se basa en tus rasgos de personalidad, los contenidos y las simulaciones que has completado.'}}
              </span>
              <span class="d-flex pb-3">{{'Recuerda: A mayor nivel, más vulnerabilidad. Un nivel bajo significa que estás
                menos vulnerable a caer en un ataque.'}}</span>
              <div>
                <button (click)="tooggleUserInfo()" nbButton hero [status]="'info'" class="button-form"
                  [shape]="'round'">{{ 'Muéstramelo'}}
                  <img src="assets/imgs/chevron-right.svg">
                </button>
              </div>
            </div>
            <ng-template #personalInfoMobile>
              <div class="personal-info-card-mobile">
                <span [innerHTML]="getPersonalVariation()">
                </span>
              </div>
            </ng-template>
            <div *ngIf="!showGlobalText; else globalInfo" class="text-lg-left global-info-card">
              <h3>{{ 'Compara tu nivel'}}</h3>
              <br>
              <span class="d-flex">
                {{ 'El Anillo compara tu nivel con el resto de tu equipo.'}}
              </span>
              <span class="d-flex pb-3">{{'Recuerda: Un nivel alto significa que tu vulenrabilidad es más alta que el
                promedio. Y uno bajo implica una vulnerabilidad menor que el promedio. '}}</span>
              <div>
                <button (click)="toogleRingInfo()" nbButton hero [status]="'info'" class="button-form"
                  [shape]="'round'">{{ 'Muéstramelo'}}
                  <img src="assets/imgs/chevron-right.svg">
                </button>
              </div>
            </div>
            <div *ngIf="!showGlobalText; else globalInfoMobile" class="text-lg-left global-info-card-mobile">
              <h3>{{ 'Tu nivel comparado'}}</h3>
              <span class="d-flex">
                {{ 'El porcentaje exterior compara tu nivel con el resto de tu equipo.'}}
              </span>
              <span class="d-flex pb-3">{{'Recuerda: Un nivel alto significa que tu vulenrabilidad es más alta que el
                promedio. Y uno bajo implica una vulnerabilidad menor que el promedio.'}}</span>
              <div>
                <button (click)="toogleRingInfo()" nbButton hero [status]="'info'" class="button-form"
                  [shape]="'round'">{{ 'Muéstramelo'}}
                  <img src="assets/imgs/chevron-right.svg">
                </button>
              </div>
            </div>
          </div>
        </div>
        <ng-template #globalInfo>
          <div class="global-variation" style="width: unset;">
            <span [innerHTML]="getGlobalVariation()">
            </span>
          </div>
        </ng-template>
        <ng-template #globalInfoMobile>
          <div class="global-info-card-mobile">
            <span [innerHTML]="getGlobalVariation()">
            </span>
          </div>
        </ng-template>
      </div>
    </div>


  </ng-container>
</ng-template>